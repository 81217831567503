import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'popup',
  styleUrls: ['popup.component.scss'],
  templateUrl: 'popup.component.html'
})
export class PopupComponent {

  @Input() public title;
  @Output() public closeClick: EventEmitter<any>;
  @ViewChild('popup') public modal: ElementRef;
  public modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {
    this.closeClick = new EventEmitter();
  }

  public open(): void {
    this.modalRef = this.modalService.open(this.modal);
  }

  public close(): void {
    this.modalRef.close();
  }
}
